/*----------------------------------------------------
@File: Default Styles
@Author: Dreemsthemes
@URL: http://wethemez.com

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: Cryptogold
@Author: Dreemsthemes
@Developed By: Rony
@Developer URL: http://rocky.obaidul.com

@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ slider
05/ photography
06/ button 
07/ work 
08/ team
09/ contact 
10/ price 
11/ portfolio 
12/ blog 
13/ sidebar  
13/ page-header  
13/ project  
13/ we-dope  
13/ progress
13/ comingsoon
13/ error
13/ footer
=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Open+Sans|Lora:400i");
.col-md-offset-right-1 {
  margin-right: 8.33333333%;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.border {
  width: 4rem;
  height: 4rem;
}

.border-dark {
  border-color: #797979!important;
}

.align-items-center {
  display: block;
  margin: 0 auto;
}

.list_style {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.row.m0 {
  padding: 0px;
  margin: 0px;
}

body {
  line-height: 30px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #797979;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

.mt_40 {
  margin-top: 40px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_80 {
  margin-top: 80px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_80 {
  margin-bottom: 80px;
}

.t_color {
  color: #040c2c;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.row_reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.bottom_line {
  width: 80px;
  height: 2px;
  margin: 25px auto 0px;
  display: inline-block;
  background: #bcbcbc;
}

.title_h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
}

.title_p {
  font-size:16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0px;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

/* Main title css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ theme_btn css =========*/
.theme_btn {
  font-size: 20px;
  font-weight: 500;
  color: #040c2c;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #040c2c;
  padding: 13px 36px;
  border-radius: 3px;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 1;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  min-width: 140px;
  text-align: center;
}

.theme_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 1px;
  background: #040c2c;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.theme_btn:hover {
  color: #fff;
}

.theme_btn:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========= header_area css ===========*/
.navbar {
  width: 100%;
  top: 0px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  z-index: 1001;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibilite: hidden;
}

@media (min-width: 1300px) {
  .navbar .custome_container {
    max-width: 1440px;
  }
}

.navbar .logo_h {
  margin-right: 0px;
}

.navbar .logo_h img {
  max-width: 100%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar .logo_h img + img {
  display: none;
}

.navbar .navbar-nav .nav-item {
  margin-left: 35px;
}

.navbar .navbar-nav .nav-item .nav-link {
  font: 600 14px/30px "Montserrat", sans-serif;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding: 0px;
  cursor: pointer;
}

.navbar .navbar-nav .nav-item .nav-link:before {
  content: "";
  background: #fff;
  opacity: 0.71;
  position: absolute;
  left: auto;
  right: 0px;
  bottom: 0px;
  width: 0%;
  height: 1.3px;
  z-index: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.navbar .navbar-nav .nav-item .nav-link.active:before, .navbar .navbar-nav .nav-item .nav-link:hover:before {
  width: 100%;
  left: 0;
}

.navbar .navbar-nav.nav-right .nav-item {
  margin-left: 0px;
}

.navbar .login {
  padding: 5px 34px;
  font: 600 14px/30px "Montserrat", sans-serif;
  background: #fff;
  border-radius: 3px;
  color: #494949;
  text-transform: uppercase;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  overflow: hidden;
  z-index: 1;
  position: relative;
  margin-top: 7px;
}

.navbar .login:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 0px;
  background: #fff;;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.navbar .login:hover {
  -webkit-box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
  color: #040c2c;
}

.navbar .login:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.navbar_fixed .navbar{
  background: #fff;
  -webkit-box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
  top: 0;
}

.navbar_fixed .logo_h img {
  display: none;
}

.navbar_fixed .logo_h img + img {
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar_fixed .navbar .navbar-nav .nav-item .nav-link {
  color: #040c2c;
}

.navbar_fixed .navbar-nav .nav-item .nav-link:before {
  background: #040c2c;
}

.navbar_fixed .login {
  border-color: #040c2c;
  color: #040c2c;
}
.navbar_fixed .login:before{
  background: #040c2c;
}

.navbar_fixed .login:hover {
  color: #fff;
}

.navbar-toggler {
  border: 0px;
  padding: 0px;
  border-radius: 0px;
  height: 80px;
  cursor: pointer;
}

.navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  background-image: -moz-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -webkit-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -ms-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  margin: auto;
  margin-bottom: 5px;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.navbar-toggler[aria-expanded="true"] span:first-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: 6px;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
  bottom: 8px;
  position: relative;
}

.navbar-toggler:focus {
  outline: none;
}

.dark_menu .navbar-nav .nav-item .nav-link{
  color: #040c2c;
}
.dark_menu .navbar-nav .nav-item .nav-link.active:before, .dark_menu .navbar .navbar-nav .nav-item .nav-link:hover:before{
  background: #040c2c;
}
.dark_menu .login{
  background: #040c2c;
  color: #fff;
}
.dark_menu .logo_h img{
  display: none;
}
.dark_menu .logo_h img + img{
  display: block;
}
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========== banner_area css =========*/
.banner_area,.banner_area_two {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.react-parallax-bgimage{
  height: 100vh;
  top: 0;
  z-index: -1;
  width: 100%;
  left: 0;
}
.react-parallax-content{
  width: 100%;
}
.banner_area:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../../image/grid-70.png") no-repeat scroll center 0/cover;
  z-index: 0;
}
.particle{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.banner_content {
  color: #fff;
  position: relative;
}

.banner_content h5 {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
  margin-bottom: 32px;
  letter-spacing: 1px;
}

.banner_content h2 {
  margin: 10px 0px;
  font-size: 55px;
  font-weight: 700;
}

.banner_content h4 {
  font-size: 22px;
  letter-spacing: 0px;
  padding: 10px 0px 30px;
}
.banner_content h4 span{
  font-weight: 700;
}

.banner_content .social_icon li {
  display: inline-block;
  margin: 0px 3px;
}

.banner_content .social_icon li a {
  font-size: 14px;
  width: 42px;
  height: 42px;
  line-height: 43px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.banner_content .social_icon li a:hover {
  background: #fff;
  color: #040c2c;
}

.banner_area_two .banner_content{
  color: #040c2c;
  position: relative;
  z-index: 3;
}
.banner_area_two .banner_content h2{
  font-size: 75px;
}
.banner_area_two .banner_content h3{
  font-size: 24px;
  padding-top: 20px;
  margin-bottom: 2px;
}
.banner_area_two .banner_content .social_icon li a{
  background: #040c2c;
}
.banner_area_two .banner_content .social_icon li a:hover{
  box-shadow: 0px 2px 35px 0px rgba(4, 12, 44, 0.5);
  color: #fff;
  transform: translateY(-4px);
}
.banner_area_two .one_img{
  position: absolute;
  top: -15px;
  left: -150px;
  opacity: 0.6;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite
}
@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}
/*========== banner_area css =========*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== about_area css ===========*/
.about_area {
  overflow: hidden;
  padding-top: 80px;
}

.about_content {
  padding-right: 88px;
  padding-bottom: 50px;
}

.about_content h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
}

.about_content h6 {
  color: #999;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-size: 16px;
}

.about_content p {
  padding-bottom: 30px;
}
.about_content .theme_btn.active{
  color: #fff;
}
.about_content .theme_btn.active:before{
  transform: scale(1);
}
.about_content .theme_btn + .theme_btn {
  margin-left: 15px;
}
.about_content .theme_btn.active:hover{
  color: #040c2c;
}
.about_content .theme_btn.active:hover:before{
  transform: scaleX(0);
}
.about_img img {
  margin-left: -50px;
}

.work_area {
  background: #fafafa;
  padding: 110px 0px 90px;
}

.work_item {
  text-align: center;
  border: 1px solid #f3f1f1;
  background: #fff;
  padding: 40px 25px 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.work_item i {
  border: 1px solid #8a8a8a;
  border-radius: 50%;
  color: #8a8a8a;
  font-size: 32px;
  height: 80px;
  line-height: 80px;
  margin-bottom: 20px;
  width: 80px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  display: inline-block;
}

.work_item h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 5px;
}

.work_item:hover {
  -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
}

/*================= about_area_two css ==============*/
.about_area_two {
  padding: 120px 0px;
}

.n_section_title {
  margin-bottom: 60px;
}

.n_section_title .top_border {
  height: 40px;
  width: 1px;
  background: #cccccc;
  margin: 0 auto;
  display: block;
}

.n_section_title h6 {
  font-size: 14px;
  color: #ff214f;
  margin: 30px 0px 10px;
}

.n_section_title h2 {
  font: 300 32px/40px "Montserrat", sans-serif;
  color: #232323;
}

.n_section_title h2 span {
  font-weight: 500;
}

.about_item {
  padding-left: 55px;
  position: relative;
}

.about_item i {
  font-size: 26px;
  position: absolute;
  left: 0;
  top: 5px;
}

.about_item h3 {
  font: 600 14px/24px "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #040c2c;
}

.about_item p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
}

.promo_section .padding {
  padding: 0px;
}

.promo_section .promo_box_one {
  background: #000;
  padding: 0px 60px;
}

.promo_section .promo_box_one .content {
  position: relative;
}

.promo_section .promo_box_one .content i {
  font-size: 80px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #fff;
  position: absolute;
  top: 0;
  left: -18px;
  color: #ff214f;
}

.promo_section .promo_box_one .content p {
  font: 600 28px/36px "Montserrat", sans-serif;
  color: #fff;
  padding-top: 80px;
  margin-bottom: 0px;
}

.promo_section .promo_video {
  position: relative;
  z-index: 1;
  text-align: center;
}

.promo_section .promo_video:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 0;
}

.promo_section .promo_video img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.promo_section .promo_video .video_btn {
  position: absolute;
  top: 50%;
  font-size: 22px;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.promo_section .promo_video .video_btn i {
  padding-right: 10px;
}

.promo_section .promo_skill {
  background: #f7f7f7;
  padding: 40px 60px;
}

.promo_section .promo_skill h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: normal;
  color: #040c2c;
  margin-bottom: 25px;
}

.promo_section .promo_skill .progress_bar_info {
  width: 100%;
}

.promo_section .promo_skill .progress_bar_info .progress {
  height: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #d0dae3;
  overflow: visible;
  margin-bottom: 20px;
  position: relative;
}

.promo_section .promo_skill .progress_bar_info .progress .progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #F24259;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

.promo_section .promo_skill .progress_bar_info .progress .count-tip {
  position: absolute;
  top: -46px;
  right: 0;
  color: #797979;
  font-size: 14px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== portfolio_area css ============*/
.portfolio_area {
  padding: 110px 0px 90px;
}

.portfolio_menu {
  margin-bottom: 50px;
}

.portfolio_menu li {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #ededed;
  border-radius: 2px;
  color: #494949;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 38px;
  margin-right: 18px;
  padding: 0 25px;
  text-transform: capitalize;
  -webkit-transition: background 0.5s ease-in-out, color 0.2s linear;
  -o-transition: background 0.5s ease-in-out, color 0.2s linear;
  transition: background 0.5s ease-in-out, color 0.2s linear;
  cursor: pointer;
}

.portfolio_menu li:hover, .portfolio_menu li.active {
  background: #040c2c;
  color: #fff;
}

.grid-item {
  margin-bottom: 30px;
}

.portfolio {
  position: relative;
}

.portfolio img {
  max-width: 100%;
}

.portfolio .item-img-overlay {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background: rgba(255, 255, 255, 0.95);
  opacity: 0;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  z-index: 2;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  cursor: pointer;
}

.portfolio .item-img-overlay .overlay-info {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.portfolio .item-img-overlay .overlay-info h6 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 15px;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.portfolio .item-img-overlay .overlay-info .icons a {
  display: inline-block;
  width: 45px;
  height: 45px;
  font-size: 16px;
  line-height: 43px;
  border-radius: 50%;
  border: 1px solid #111;
  background: #111;
  color: #eee;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 0px 5px;
}

.portfolio .item-img-overlay .overlay-info .icons a:hover {
  background: #fff;
  color: #040c2c;
}

.portfolio:hover .item-img-overlay {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.portfolio:hover .overlay-info h6 {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/*================== project_count_area css ================*/
.project_count_area {
  background: #fafafa;
  padding: 120px 0px;
}

.project_count_area .counter_item i {
  font-size: 35px;
  margin-bottom: 25px;
}

.project_count_area .counter_item .t_color {
  font: 600 35px/40px "Montserrat", sans-serif;
}

.project_count_area .counter_item p {
  margin-bottom: 0px;
  font-size: 16px;
}

.p_count_two {
  background: #fff;
  padding: 80px 0px;
}

.p_count_two .counter_item i {
  color: #ff214f;
}

.bg_two {
  background: #fafafa;
}

/*=============== testimonial_area css  ==============*/
.testimonial_area {
  background: url("/image/portfolio/img-3.jpg") no-repeat scroll center 0/cover;
  position: relative;
  z-index: 1;
  padding: 120px 0px;
}

.testimonial_area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
  width: 100%;
  height: 100%;
}

.testimonial_area .testimonial_slider {
  text-align: center;
  padding: 0px 100px;
}

.testimonial_area .testimonial_slider .item {
  color: #fff;
}

.testimonial_area .testimonial_slider .item .author_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #ededed;
  background: #fff;
}

.testimonial_area .testimonial_slider .item .author_img img {
  max-width: 100%;
  width: auto;
}

.testimonial_area .testimonial_slider .item h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 15px 0 0px;
}

.testimonial_area .testimonial_slider .item span {
  font: 400 13px "Open Sans", sans-serif;
  opacity: 0.6;
}

.testimonial_area .testimonial_slider .item p {
  margin-bottom: 0px;
  margin-top: 15px;
}
.testimonial_area .testimonial_slider .slick-dots {
  left: 0;
  position: relative;
}
.testimonial_area .testimonial_slider .slick-dots li{
  margin: 0px;
}
.testimonial_area .testimonial_slider .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  opacity: 0.50;
  margin: 0px 0px 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.testimonial_area .testimonial_slider .slick-dots li button:before{
  display: none;
}

.testimonial_area .testimonial_slider .slick-dots .slick-active button{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
}

.team_area {
  padding: 120px 0px;
}

.team_area .team_item img {
  max-width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.team_area .team_item .content {
  text-align: center;
  padding-top: 25px;
}

.team_area .team_item .content h5 {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #040c2c;
}

.team_area .team_item .content p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
}

.team_area .team_item .content .social-links {
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
}

.team_area .team_item .content .social-links li {
  display: inline-block;
}

.team_area .team_item .content .social-links li a {
  font-size: 14px;
  color: #939393;
  padding: 0px 3px;
}

.team_area .team_item .content .social-links li a:hover {
  color: #ff214f;
}

.team_area .team_item:hover img {
  -webkit-filter: none;
  filter: none;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== clients_logo_area css ============*/
.clients_logo_area {
  padding: 120px 0px;
}

.clients_slider {
  text-align: center;
}

.clients_slider .item a {
  display: block;
  text-align: center;
}

.clients_slider .item img {
  max-height: 100px;
  width: auto;
  opacity: 0.7;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.clients_slider .item:hover img {
  opacity: 1;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============== blog_area css =============*/
.blog_area {
  background: #fafafa;
  padding: 120px 0px;
}

.bg_w {
  background: #fff;
}

.blog_post {
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_post .blog_img {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.blog_post .blog_img .post_date {
  position: absolute;
  width: 55px;
  height: 60px;
  font: 700 18px "Montserrat", sans-serif;
  color: #494949;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  top: 20px;
  right: 20px;
  padding-top: 8px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_post .post_content {
  padding: 25px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-top: 0px;
}

.blog_post .post_content .blog-meta span {
  font: 500 15px/15px "Montserrat", sans-serif;
  color: #494949;
  display: inline-block;
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span i {
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span a {
  color: #494949;
}

.blog_post .post_content .blog-meta span + span {
  border-left: 1px solid #494949;
  padding-left: 12px;
}

.blog_post .post_content h2 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  color: #494949;
  padding: 10px 0px 8px;
}
.blog_post .post_content h2:hover{
  color: #040c2c;
}
.blog_post .post_content .read_btn {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #797979;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.blog_post .post_content .read_btn i{
  vertical-align: middle;
  padding-left: 3px;
  transition: all 0.2s linear;
  display: inline-block;
}
.blog_post .post_content .read_btn:hover {
  color: #040c2c;
}
.blog_post .post_content .read_btn:hover i{
  transform: translateX(5px);
}
.blog_post:hover {
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
}

.blog_post:hover .post_date {
  background: #040c2c;
  color: #fff;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ contact-area css ===============*/
.contact-area {
  padding: 120px 0px;
}

.contact-area h4 {
  font: 600 35px/40px "Montserrat", sans-serif;
  color: #040c2c;
  text-transform: capitalize;
  padding-bottom: 24px;
}

.contact-area .input_form form .form-control {
  font-size: 14px;
  line-break: 24px;
  font-weight: 400;
  color: #494949;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ededed;
  border-radius: 0px;
  padding-left: 20px;
  text-transform: capitalize;
  min-height: 44px;
  margin-bottom: 30px;
}

.contact-area .input_form form .form-control.placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control:-moz-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control::-moz-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control::-webkit-input-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control.error {
  margin-bottom: 0px;
}

.contact-area .input_form form .form-control.error + .error {
  font: 400 10px/30px "Montserrat", sans-serif;
  color: #494949;
  background: none;
  border: 0px;
  margin-bottom: 0px;
  display: block;
}

.contact-area .input_form form textarea.form-control {
  resize: none;
  height: 142px;
  padding-top: 12px;
}

.contact-area .input_form form .send_btn {
  font-size: 16px;
  border-radius: 4px;
  color: #040c2c;
  padding: 14px 38px 14px 42px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: transparent;
}
.contact-area .input_form form .send_btn:hover{
  color: #fff;
}
.contact-area .input_form #success, .contact-area .input_form #error {
  position: absolute;
  top: -15px;
  display: block;
  right: 0;
  left: 0;
  bottom: -15px;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.contact-area .input_form #success p, .contact-area .input_form #error p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}

.contact-area .input_form #success {
  color: lawngreen;
}

.contact-area .input_form #error {
  color: orangered;
}

.contact-area .contact_info {
  padding-left: 50px;
}

.contact-area .contact_info ul {
  display: block;
}

.contact-area .contact_info ul .item {
  cursor: pointer;
  display: block;
}

.contact-area .contact_info ul .item .media i {
  color: #494949;
  line-height: 28px;
  text-align: center;
  font-size: 18px;
  padding-right: 10px;
}

.contact-area .contact_info ul .item .media .media-body {
  vertical-align: middle;
}

.contact-area .contact_info ul .item .media .media-body a {
  color: #797979;
  font: 400 16px/28px "Montserrat", sans-serif;
}

.contact-area .contact_info ul .item + .item {
  padding-top: 15px;
}

/*============contact map=============*/
.contact_map {
  position: relative;
  height: 500px;
}


/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========= Start footer area css ========*/
.footer-area {
  background: #040c2c;
  position: relative;
  padding: 60px 0px 60px;
}

.footer-area .footer-content {
  text-align: center;
}

.footer-area .footer-content .logo {
  display: inline-block;
  margin-bottom: 30px;
}

.footer-area .footer-content .logo img {
  max-width: 100%;
}

.footer-area .footer-content ul li {
  display: inline-block;
  padding: 0px 5px;
}

.footer-area .footer-content ul li a {
  width: 35px;
  height: 35px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  line-height: 35px;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.footer-area .footer-content ul li a:hover {
  background: #fff;
  color: #494949;
}

.footer-area .footer-content h6 {
  font: 400 14px/20px "Lora", serif;
  color: #999;
  padding-top: 15px;
}

.footer-area .footer-content h6 a {
  color: #fff;
}

.footer-area .footer-content p {
  font: 400 10px/18px "Montserrat", sans-serif;
  color: #fff;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.bg_color{
  background: #fafafa;
}
.work_area_two{
  background: #fff;
  padding: 110px 0px 90px;
}
.bg_w{
  background: #fff;
}


/*========= End footer area css ========*/
/*---------------------------------------------------- */

/*# sourceMappingURL=style.css.map */